import React from 'react'
import tw, { styled } from 'twin.macro'
import Check from '../../images/Check.svg'

const TextWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #263c5c;
    padding: 0 0 1rem 0;
  }
  a {
    text-decoration: underline;
  }
  p {
    padding-bottom: 1rem;
    font-size: 18px;
    color: #263c5c;
    .check-list {
      color: #263c5c;
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }
    .check-list::before {
      content: '';
      display: block;

      margin-right: 12px;
      background-size: cover;
      background-image: url(${Check});
      width: 16px;
      height: 16px;
      padding: 0 9px;
    }
  }
  ul {
    color: #263c5c;
    margin-left: 1rem;
    padding-bottom: 1rem;
    li {
      list-style: disc;
    }
  }
  iframe {
    width: 100%;
    height: 450px;
  }
`

const Text = ({ text }) => {
  return (
    <TextWrapper tw="py-8">
      <div
        dangerouslySetInnerHTML={{
          __html: text.text.html,
        }}
        tw=""></div>
    </TextWrapper>
  )
}
export default Text
